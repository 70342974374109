import { Link } from "gatsby"
import React, {useState} from "react"

import LOGO_NORMAL from "../images/svg/laura-farias-logo.svg";
import LOGO_WHITE from "../images/svg/laura-farias-logo-white.svg";

import NavMenu from "./menu-nav";
import Icon from "./icon";
import toast, { Toaster } from 'react-hot-toast';
import { useLocation } from "@reach/router";
import { useEffect } from "react";
import {cleanDevelopmentAction} from "../redux/developmentsDucks";

import { connect } from "react-redux";

import ReactGA from 'react-ga';

function Main({dispatch}) {

    const [open,setOpen] = useState(false);
    const location = useLocation()
    const pathname = location.pathname.split("/")[1]

    ReactGA.initialize('UA-206895121-1');
    ReactGA.pageview(window.location.pathname + window.location.search);

    const isResultTrue = () => {
        if(pathname.includes('venta') || pathname.includes('alquiler')){
            return true;
        }
        return false
    }

    useEffect(() => {
        if(!location.pathname.includes('/emprendimientos')){
            dispatch(cleanDevelopmentAction())
        }
    },[location])

    const staticBody = () => {
        var body = document.body;
        body.classList.toggle("overflow-hidden");
    }

    return (
    <header id="header" className={"blur " + (open ? 'opened ' : '')}>
        <Toaster
        toastOptions={{
            className: 'toast-className',
            style: {
                maxWidth: 'fit-content',
                
              },
        }} />
        <nav className={"brand " + (isResultTrue() ? 'out-blur ' : '')}>
            <div className="container-fluid">
                <div className="row justify-content-center align-items-center">
                    <div className="col-6 justify-content-start">
                        <Link to={"/"} className="logo d-none d-lg-block">
                            <LOGO_NORMAL className="img color" />
                            <LOGO_WHITE className="img white" />
                        </Link>
                        <Link to={"/"}  className="logo d-lg-none">
                            <LOGO_NORMAL className="img color" />
                            <LOGO_WHITE className="img white" />
                        </Link>
                    </div>
                    <div className="col-6 text-right d-flex justify-content-end">
                        <Link className="me-4 icon-favorites d-flex align-items-center justify-content-center" to={"/favoritos"}><i className={"icon-like " + (location.pathname.includes("favoritos") ? 'active' : '')}></i> </Link>
                        <button id="toggle-nav" onClick={() => setOpen(!open) + staticBody()}>
                            <div className="d-flex align-items-center"> 
                                <span className="status_nav d-none d-lg-inline">Menú </span> 
                                <span className="ms-4"><i></i><i></i><i></i></span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </nav>
        <NavMenu />
  </header>
)}

export default connect(state => ({
}),null)(Main);