import { data } from "jquery";

export const getCover = (photos) => {
    if(photos?.length > 0){
        if(photos.find(photo => photo.is_front_cover)){
            return photos.find(photo => photo.is_front_cover);
        }
        else{
            return photos[0];
        }
    }
    return {original:"https://mediahaus.com.ar/noimage.jpg"}
}

export const getPreviewSlider = (photos) => {
    let array=[];
    if(photos){
        if(photos.length >= 5){
            array = photos.slice(0,5)
        }
        array[0] = getCover(photos)
    }
    return array
}

export const getPhotos = (data) => {
    if(data?.photos?.length > 0){
        return data.photos.filter(photo => !photo.is_blueprint)
    }
    return [{original:"https://mediahaus.com.ar/noimage.jpg"}]
} 

export const getDescription = (data,characters,notData = false) => {
    if(data.description ){
        if(data.description.length === 0)  return 'Sin descripción'
        const description = data.description.replaceAll("&nbsp;","").slice(0,characters).split("\n")
        return description
    }
    return ['Sin descripción']
}

export const descriptionIsNull = (data) => {
    if(data.description){
        if(data.description.length === 0)  return true
    }
    return false
}

export const getBrText = (text) => {
    const description = text.split("\n")
    return description
}

export const existDevelopment = (developments) => {
    if(developments?.data?.objects?.length > 0){
        return true
    }
    return false
}

export const existOperation = (operation,filters) => {
    switch(operation){
        case 'venta':
            if(filters?.data?.objects?.operation_types.find(operation => operation.operation_type === 1)) 
                return true
            break;
        case 'alquiler':
            if(filters?.data?.objects?.operation_types.find(operation => operation.operation_type === 2))
                return true
            break;
    }
    return false
}

export const getMessageWhatsapp = (development) => {
    if(development?.name){
      return 'Quería consultar sobre el emprendimiento ' + development?.name
    }
    else {
      return "¡Hola!"
    }
}

export const existArray = (array) => {
    console.log(array.find(array => array === '').length)
    if(array.find(array => array === '')){
        return false
    }
    return true;
}


export const getPhonesText = (phones) => {
    let text = ''
    if(phones){
        phones.forEach((phone,index) => {
            text += phone + (index === phones.length - 1 ? '' : ' / ')
        })
    }
    return text
}
